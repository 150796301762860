import Layout from "@/layout/Container.vue"

export const commonRouter = [
  {
    path: '/',
    redirect: '/company/organ'
  },
  {
    path: '/enroll',
    name: 'Enroll',
    meta: {
      title: "机构认证",
    },
    component: () => import("@/views/enroll")
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: "登录",
    },
    component: () => import("@/views/login/index")
  },
  // {
  //   path: '/home',
  //   name: 'Home',
  //   meta: {
  //     title: "工作台"
  //   },
  //   component: Layout,
  //   children: [
  //     {
  //       path: '/home/index',
  //       name: 'HomeIndex',
  //       meta: {
  //         title: '数据总览',
  //         parent: '工作台'
  //       },
  //       component: () => import("@/views/home/index")
  //     },
      
  //   ]
  // },
  {
    path: '/company',
    name: 'Company',
    meta: {
      title: "机构组织",
    },
    component: Layout,
    children: [
      {
        path: '/company/organ',
        name: 'CompanyOrgan',
        meta: {
          title: '机构信息',
          parent: '机构组织'
        },
        component: () => import("@/views/company/organ/index")
      },
      {
        path: '/company/account',
        name: 'CompanyAccount',
        meta: {
          title: '账号列表',
          parent: '机构组织'
        },
        component: () => import("@/views/company/account/index")
      },
    ]
  },
  {
    path: '/product',
    name: 'Product',
    meta: {
      title: "产品管理",
    },
    component: Layout,
    children: [
      {
        path: '/product/goods',
        name: 'ProductGoods',
        meta: {
          title: '产品列表',
          parent: '产品管理'
        },
        component: () => import("@/views/product/goods/index")
      },
      {
        path: '/product/score',
        name: 'ProductScore',
        meta: {
          title: '产品评价',
          parent: '产品管理'
        },
        component: () => import("@/views/product/score/index")
      },
    ]
  },
  {
    path: '/order',
    name: 'Order',
    meta: {
      title: "订单管理",
    },
    component: Layout,
    children: [
      {
        path: '/order/list',
        name: 'OrderList',
        meta: {
          title: '订单列表',
          parent: '订单管理'
        },
        component: () => import("@/views/order/index")
      },
    ]
  },
  {
    path: '/settle',
    name: 'Settle',
    meta: {
      title: "结算管理",
    },
    component: Layout,
    children: [
      {
        path: '/settle/account',
        name: 'SettleAccount',
        meta: {
          title: '账户信息',
          parent: '结算管理'
        },
        component: () => import("@/views/settle/account/index")
      },
      {
        path: '/settle/list',
        name: 'SettleList',
        meta: {
          title: '结算列表',
          parent: '结算管理'
        },
        component: () => import("@/views/settle/list/index")
      },
    ]
  },
  
  
  {
    path: '*',
    name: '404',
    meta: {
      title: '404',
    },
    component: () => import("@/views/404")
  }
]